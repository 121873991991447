import type { GetStaticPropsContext } from 'next'
import commerce from '@lib/api/commerce'
import LayoutError from '../components/common/Layout/LayoutError'
import { IPagePropsProvider } from '@framework/contracts/page-props/IPagePropsProvider'
import { PagePropType, getPagePropType } from '@framework/page-props'
import { IMAGE_ASSET_BASE_URL } from '@framework/utils/constants'

export async function getStaticProps({
  preview,
  locale,
  locales,
}: GetStaticPropsContext) {
  const config = { locale, locales }
  const { pages } = await commerce.getAllPages({ config, preview })
  const { categories, brands } = await commerce.getSiteInfo({ config, preview })
  const props: IPagePropsProvider = getPagePropType({ type: PagePropType.COMMON }) as IPagePropsProvider
  const commonPageProps = await props.getPageProps({ cookies: {} })
  return {
    props: {
      ...commonPageProps,
      pages,
      categories,
      brands,
    },
    revalidate: 200,
  }
}

export default function NotFound({ deviceInfo }: any) {
  const { isMobile, isIPadorTablet } = deviceInfo
  return (
    <>
      {!(isMobile || isIPadorTablet) && (
        <>
          <div className="w-full py-14">
            <div className="error-container">
              <div className="w-full mb-8 text-center img-section">
                <img
                  width={152}
                  height={152}
                  src={`${IMAGE_ASSET_BASE_URL}/icons/error-icon.svg`}
                  alt="404 Error Image"
                  className="inline-block"
                />
              </div>
              <div className="w-full text-center error-text-section">
                <h1 className="mb-2 font-semibold text-black sm:text-2xl">
                  404 : Page Not Found
                </h1>
                <p className="text-black">
                  Check that you typed the address correctly. Maybe go back to
                  your previous page or try using our site search to find
                  something specific.
                </p>
              </div>
            </div>
          </div>
        </>
      )}
      {(isMobile || isIPadorTablet) && (
        <>
          <div className="w-full px-4 py-8">
            <div className="error-container">
              <div className="w-full mb-4 text-left img-section">
                <img
                  width={54}
                  height={54}
                  src={`${IMAGE_ASSET_BASE_URL}/icons/error-icon.svg`}
                  alt="404 Error Image"
                  className="inline-block"
                />
              </div>
              <div className="w-full mb-6 text-left error-text-section">
                <h1 className="mb-2 text-base font-semibold text-black">
                  404 : Page Not Found
                </h1>
                <p className="text-xs text-brown-light">
                  Check that you typed the address correctly. Maybe go back to
                  your previous page or try using our site search to find
                  something specific.
                </p>
              </div>
              <div className="w-full">
                <a
                  href="/"
                  className="block p-4 text-sm font-semibold text-center text-white bg-black"
                >
                  Back to Homepage
                </a>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  )
}

NotFound.Layout = LayoutError
